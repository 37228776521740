import "./root.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import LendingPage from "./pages/LendingPage";

const App = () => {
  return (
    <>
      <LendingPage />
    </>
  );
};

export default App;
