import React from "react";
import Title from "../heading-title/Title";

const NewPlatform = () => {
  return (
    <div>
      <Title>
        <div className="center-column">
          {/* <h1 className="f-40 gilroy w-43 mobile-w-100"> */}
          <h1 className="f-40 gilroy w-50 mobile-w-100">
            <span className="second-color">Manage</span> and{" "}
            <span className="second-color">find providers, </span>
            in this all new platform.
          </h1>
          <p className="text-color font-inter gap-15 w-60 f-20 mobile-w-100">
            WorkRobin keeps your providers' work on-brand,on message, and on
            time. Innovative features make creating and sharing your work feel
            effortless.
          </p>
        </div>
      </Title>

      <div className="gap-70 mobile-mt-35">
        {/* <img className="w-100" src="/assets/images/VideoPlay.png" alt="" /> */}
        <img
          className="w-100 provider-buyer-manageImg"
          src="/assets/images/buyer_provider_manage.jpg"
          alt=""
        />
      </div>
    </div>
  );
};

export default NewPlatform;
