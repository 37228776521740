import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./how-it-work.css";
import Title from "../heading-title/Title";
const HowItWorks = () => {
  const howItWorkItems = [
    {
      title: "Post Jobs Effortlessly",
      description: "Easily create and manage work orders in just a few clicks.",
    },

    {
      title: "Find the Right Talent",
      description: "Use advanced search to quickly find qualified contractors.",
    },
    {
      title: "Automate Onboarding",
      description: "Streamline the process for faster, error-free onboarding.",
    },
    {
      title: "Manage Work in Real-Time",
      description:
        "Track the progress of all your work orders from start to finish.",
    },
    {
      title: "Fast & Reliable Payments",
      description:
        "Ensure prompt payments with automated processing once jobs are completed.",
    },
    {
      title: "Stay Compliant",
      description:
        "Keep your business compliant with built-in regulatory features.",
    },
  ];
  return (
    <div>
      <Title>
        <div className="center-column ">
          <h1 className="f-40 f-34 gilroy w-45 mobile-w-100">
            Simple <span className="second-color">Tools for </span>
            <span className="main-color">Better Results</span>
          </h1>
          <p className="text-color font-inter gap-10 w-50 f-20 mobile-w-100">
            WorkRobin is designed to help businesses and providers.
          </p>
        </div>
      </Title>

      <Container>
        <Row>
          {howItWorkItems?.map((item, index) => {
            return (
              <Col
                key={index}
                md={4}
                className="gap-70 mobile-mt-15 padding-left-right "
              >
                <div className="text-center">
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="round-choose-icon ">
                      <img src="/assets/images/active-color-work.png" alt="" />
                    </div>
                  </div>

                  <div className="f-bold f-22 mt-3">{item?.title}</div>
                  <p className="text-color f-17 mt-2">{item?.description}</p>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default HowItWorks;
