import React from "react";
import { Accordion } from "react-bootstrap";
import "./faq.css";
import Title from "../heading-title/Title";
const Faq = () => {
  const accordionsItems = [
    {
      title: "What is WorkRobin?",
      description:
        "WorkRobin connects businesses and contractors, automating tasks like onboarding, work orders, and payments.",
    },

    {
      title: "How do I get paid as a provider?",
      description:
        "Payment is processed automatically once the job is completed and approved.",
    },

    {
      title: "How much does WorkRobin cost to join?",
      description: "It’s free to join. Fees apply only for completed projects.",
    },

    {
      title: "Can WorkRobin help me find remote jobs?",
      description:
        "Yes, WorkRobin supports both onsite and remote work opportunities.",
    },

    {
      title: "How can I find the right contractors on WorkRobin?",
      description:
        "Use the advanced search tools to find vetted contractors that match your needs.",
    },

    {
      title: "Is my data secure on WorkRobin?",
      description:
        "Yes, your data is secured with encryption and compliance with industry standards.",
    },
    {
      title: "Learn More?",
      description: "Contact us at support@workrobin.com",
    },
  ];

  return (
    <div className="gap-100 mobile-mt-35">
      <Title>
        <div className="center-column">
          {/* <h1 className="f-40 gilroy w-38 mobile-w-100"> */}
          <h1 className="f-40 gilroy w-45 mobile-w-100">
            Frequently Asked Questions
          </h1>
          {/* <p className="text-color font-inter gap-15 f-20 w-38 mobile-w-100">
            Occaecat sunt dolor sunt ut eu veniam est aute tempor non. elit
            aliqua sunt ipsum esse.
          </p> */}
        </div>
      </Title>
      <div className="gap-80 mobile-mt-35">
        <Accordion className="custom-accordion">
          {accordionsItems?.map((accordion, index) => {
            return (
              <div key={index}>
                <Accordion.Item eventKey={index}>
                  <Accordion.Header className="gilroy">
                    <h5 className="f-bold">{accordion?.title}</h5>
                  </Accordion.Header>
                  <Accordion.Body>{accordion?.description}</Accordion.Body>
                </Accordion.Item>
                <div style={{ borderBottom: "1px solid #E7E7E7" }}></div>
              </div>
            );
          })}
        </Accordion>
      </div>
    </div>
  );
};

export default Faq;
