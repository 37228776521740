import React, { Fragment, useState } from "react";

import { Col, Row } from "react-bootstrap";
import "./benefits-section.css";
import MyButton from "../common/Button";
import Title from "../heading-title/Title";
const BenefitsSection = () => {
  const [activeBenefits, setActiveBenefits] = useState("buyers");
  const [activeIndex, setActiveIndex] = useState(0);

  const benefits = {
    buyers: [
      {
        title: "Post and Manage Jobs Efficiently",
        img: "post_manage_orders_1.png",
        subTitle:
          "Easily post job opportunities and manage your workflow in one place.",
      },
      {
        title: "Find the Right Contractors Fast",
        img: "search_contractors.png",
        subTitle:
          "Search and connect with qualified contractors quickly to get your work done.",
      },
      {
        title: "Simplify Payments with Automation",
        img: "provider_payment.png",
        subTitle:
          " Automate your payment processes for smoother and faster transactions.",
      },
    ],
    providers: [
      {
        title: "Discover Jobs that Match Your Skills",
        img: "provider_work.png",
        subTitle:
          "Find job opportunities that align with your expertise and experience.",
      },

      {
        title: "Get Paid Quickly and Securely",
        img: "Payment_provider.png",
        subTitle: "Receive payments on time and with secure processing.",
      },

      {
        title: "Build Lasting Business Connections",
        img: "provider_list.png",
        subTitle:
          "Create valuable relationships with reputable businesses for long-term opportunities.",
      },
    ],
  };
  return (
    <div>
      <Title>
        <div className="center-column">
          <h1 className="f-40 gilroy w-60 mobile-w-100">
            Why Choose <span className="second-color"> WorkRobin?</span>
          </h1>
        </div>
      </Title>
      <div className="gap-50 text-center mobile-mt-35">
        <MyButton
          className={`f-16 mobile-p-17 mobile-w-77 ${
            activeBenefits == "buyers" ? "" : "btn-common-outline text-gray"
          }`}
          title={"For Buyers"}
          onClick={() => setActiveBenefits("buyers")}
        />
        <MyButton
          onClick={() => setActiveBenefits("providers")}
          className={`ms-4 gray-color f-16 mobile-ms-0 mobile-mt-15  mobile-w-77 ${
            activeBenefits == "providers"
              ? "text-white"
              : "btn-common-outline text-gray"
          }`}
          title={"For Providers"}
        />
      </div>
      <div className="post_and_manage-bg">
        <Row>
          <Col md={4}>
            {benefits[activeBenefits]?.map((item, index) => (
              <div
                key={index}
                onClick={() => setActiveIndex(index)}
                style={{
                  borderTop: index == 0 && "none",
                  borderRadius:
                    index == 1 || index == 2 ? "0px" : "10px 0px 0px 0px",
                }}
                className={` ${
                  activeIndex == index ? "active-toolbar" : "toolbar"
                }`}
              >
                <div>
                  <div className="flex">
                    <div className="d-flex align-items-center">
                      <span>
                        <img src={`/assets/images/users.png`} alt="" />
                      </span>
                      <span className="f-20 ms-3 mt-1">{item?.title}</span>
                    </div>
                    <span>
                      <img src="/assets/images/choose-right.png" alt="" />
                    </span>
                  </div>

                  <p
                    style={{ marginLeft: "38px" }}
                    className="mt-2 text-color f-400 f-17 mobile-mt-15"
                  >
                    {item?.subTitle}
                  </p>
                </div>
              </div>
            ))}
          </Col>

          <Col md={8}>
            <div className="centered-section">
              <div>
                {benefits[activeBenefits]?.map((item, index) => {
                  return (
                    <div key={index}>
                      {activeIndex == index && (
                        <img
                          className=" img-style-benefit mobile-mt-15"
                          src={`/assets/images/${item?.img}`}
                          alt="img"
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BenefitsSection;
