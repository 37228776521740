import React from "react";

const MyButton = ({ className = "", title, style = {}, img = "", ...rest }) => {
  return (
    <button style={style} className={`btn-common ${className}`} {...rest}>
      {title}
      {img && <img src={img} alt="button-icon" className="ms-2" />}
    </button>
  );
};

export default MyButton;
