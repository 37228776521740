import React, { useState } from "react";
import MyButton from "../common/Button";
import "./manage-work.css";
import { Col, Row } from "react-bootstrap";
import { Input } from "../common/Input";

const ManageWork = () => {
  // const handelSubmitContact = (e) => {
  //   e.preventDefault();
  //   e.target.reset();
  //   try {
  //   } catch (error) {}
  // };

  const [activeTaB, SetActiveTab] = useState(1);
  return (
    <section className="join-the-lending-page mobile-mt-35 gap-50 mb-2">
      <div>
        <div className="text-white m-auto w-65 mobile-w-100">
          <div className="f-bold f-40">
            Join the leading labor marketplace for contractors and providers
          </div>
        </div>
        <p style={{ color: "#E4DBDB" }} className="gap-10">
          WorkRobin helps you automate everything from onboarding to payment, so
          you can grow your business
        </p>

        {/* <div className="gap-50 mobile-mt-35">
          <a href="#contact">
            <MyButton
              onClick={() => SetActiveTab(1)}
              className={` font-16 mobile-p-17 mobile-w-77 ${
                activeTaB == 1
                  ? "bg-white text-black"
                  : "bg-transparent border-btn-join-lending"
              }`}
              title={"For Companies, Our Buyers"}
            />
          </a>
          <a href="#contact">
            <MyButton
              onClick={() => SetActiveTab(2)}
              className={`ms-3  text-white font-16 mobile-w-77  mobile-mt-15 mobile-ms-0 ${
                activeTaB == 2
                  ? "bg-white black"
                  : "bg-transparent border-btn-join-lending"
              } `}
              title={"For Technician, Our Provides "}
            />
          </a>
        </div> */}
      </div>
    </section>
    // <section className="join-the-lending-page gap-100 mobile-mt-35">
    //   <Row>
    //     <Col md={7}>
    //       <div
    //         style={{ height: "400px" }}
    //         className="d-flex justify-content-center align-items-center flex-column"
    //       >
    //         <div className="mobile-w-100 d-flex justify-content-center">
    //           <div className="f-bold text-center f-40 text-white w-60 mobile-w-100">
    //             Join the lending labor marketplace for contractors and providers
    //           </div>
    //         </div>
    //         <p style={{ color: "#E4DBDB" }} className="gap-10 text-center">
    //           WorkRobin helps you automate everything from onboarding to payment
    //           so you can grow your business
    //         </p>

    //         {/* <div className="gap-50 mobile-mt-35 text-center">
    //           <MyButton
    //             className="bg-white text-black font-16 mobile-p-17 mobile-w-77"
    //             title={"For Companies, Our Buyers"}
    //           />
    //           <MyButton
    //             className="ms-3 bg-transparent  text-white font-16 border-btn-join-lending mobile-mt-15 mobile-ms-0 "
    //             title={"For Technician, Our Provides"}
    //           />
    //         </div> */}
    //       </div>
    //     </Col>
    //     <Col md={5}>
    //       <div className="d-flex align-items-center">
    //         <form onSubmit={handelSubmitContact}>
    //           <Input required placeholder="Enter Name" className="mb-4 mt-1" />
    //           <Input
    //             required
    //             placeholder="Enter Email"
    //             type="email"
    //             className="mb-4"
    //           />
    //           <Input
    //             required
    //             placeholder="Enter Message"
    //             className="mb-4"
    //             multiline={"multiline"}
    //           />
    //           <MyButton
    //             type="submit"
    //             className="bg-white w-25 text-black font-16 mobile-p-17 mobile-w-77"
    //             title={"Submit"}
    //           />
    //         </form>
    //       </div>
    //     </Col>
    //   </Row>
    // </section>
  );
};

export default ManageWork;
