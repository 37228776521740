import React, { useState } from "react";
import Header from "../components/header/Header";
import { Col, Container, Row } from "react-bootstrap";
import Title from "../components/heading-title/Title";
import MyButton from "../components/common/Button";
import BenefitsSection from "../components/benefits/BenefitsSection";
import HowItWorks from "../components/how-it-work/HowItWorks";
import ManageWork from "../components/manage/ManageWork";
import Footer from "../components/myFooter/Footer";
import Faq from "../components/faq/Faq";
import HeroSection from "../components/home/HeroSection";
import NewPlatform from "../components/new-platform/NewPlatform";
import PlatformBuyerProviderRoles from "../components/buyerAndProoviderRole/PlatformBuyerProviderRoles";
import Contact from "../components/contact/Contact";

const LendingPage = () => {
  return (
    <div>
      {/* header */}
      <Header />
      {/* section */}
      <section id="home" className="mobile-mt-15">
        <HeroSection />
      </section>

      <Container>
        <section className="gap-150 mobile-mt-35">
          <NewPlatform />
        </section>

        <section id="benefits" className="gap-100 mobile-mt-35">
          <BenefitsSection />
        </section>

        <section id="how-it-work" className="gap-150 mobile-mt-35">
          <HowItWorks />
        </section>

        <section className="gap-150 mobile-mt-35">
          <PlatformBuyerProviderRoles />
        </section>

        <section id="faqs">
          <Faq />
        </section>

        <section id="contact">
          <Contact />
        </section>
      </Container>
      <Container fluid>
        <ManageWork />
      </Container>
      {/* 
      <section className="ps-5 pe-5 mobile-p-l-15  mobile-p-r-15">
        <ManageWork />
      </section> */}

      {/* footer */}
      {/* <footer className="gap-50">
        <Container fluid>
          <Footer />
        </Container>
      </footer> */}
    </div>
  );
};

export default LendingPage;
