import React from "react";
import { Container, Row } from "react-bootstrap";

const Title = (props) => {
  return (
    <Container className="gap-70">
      <Row>
        <div className="text-center m-auto ">{props.children}</div>
      </Row>
    </Container>
  );
};

export default Title;
