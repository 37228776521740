import React from "react";
import { Col, Row } from "react-bootstrap";

const PlatformBuyerProviderRoles = () => {
  return (
    <Row>
      <Col md={6} className="flex">
        <div>
          <h1 className="f-40 gilroy w-65 mobile-w-100">
            A <span className="second-color">platform</span> for all{" "}
            <span className="main-color"> buyers</span> and{" "}
            <span className="main-color">providers</span>
          </h1>
          <p className="text-color font-inter gap-15 f-20 w-70 mobile-w-100">
            Our independent contactor management system helps you automate
            everything from onboarding to payment so you can grow your business
            see how is works.
          </p>
        </div>
      </Col>
      <Col md={6}>
        <img
          className="mobile-platform"
          src="/assets/images/platform.png"
          alt=""
        />
      </Col>
    </Row>
  );
};

export default PlatformBuyerProviderRoles;
