import React from "react";

export const Input = ({
  type = "text",
  className = "",
  multiline,
  ...rest
}) => {
  return (
    <>
      {multiline ? (
        <textarea
          type="text"
          className={` my-message-input ${className}`}
          {...rest}
        />
      ) : (
        <input
          required
          type={type}
          className={`my-input ${className}`}
          {...rest}
        />
      )}
    </>
  );
};
