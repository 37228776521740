import React, { useState } from "react";
import Title from "../heading-title/Title";
import { Input } from "../common/Input";
import MyButton from "../common/Button";
import { Col, Row } from "react-bootstrap";
import axios from "axios";

const Contact = () => {
  const [loader, setLoader] = useState(false);
  const handelSubmitContact = async (e) => {
    try {
      e.preventDefault();
      const formData = new FormData(e.target);
      var data = {};
      formData.forEach(function (value, key) {
        data[key] = value;
      });
      setLoader(true);
      const response = await axios.post(
        "https://wkrbndev.workrobin.com/api/v1/contact/submit",
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (response?.data.status == 1) {
        alert("Form submitted successfully!");
        e.target.reset();
      } else {
        throw new Error(response.data.data);
      }
    } catch (error) {
      alert("You are already on our waitlist");
    } finally {
      setLoader(false);
    }
  };

  return (
    <div className="gap-100">
      <Title>
        <div className="center-column">
          <h1 className="f-40 gilroy w-60 mobile-w-100">
            Be the First to <span className="second-color">Experience</span>{" "}
            <span className="main-color">WorkRobin</span>
          </h1>
        </div>
      </Title>
      <p className="text-color text-center font-inter f-20 mt-2">
        Join our waitlist to get early access and exclusive updates!
      </p>
      <Row className="gap-40 mobile-mt-10">
        <Col md={{ span: 8, offset: 2 }}>
          <form onSubmit={handelSubmitContact}>
            <Input
              name="name"
              required
              placeholder="Enter Name"
              className="mb-4 mt-1"
            />
            <Input
              required
              placeholder="Enter Email"
              type="email"
              name="email"
              className="mb-4"
            />
            <select className="my-input mb-4" name="role" id="">
              <option value="buyer">Buyer</option>
              <option value="provider">Provider</option>
            </select>
            <br />
            <MyButton
              type="submit"
              // className="font-16 w-25 mobile-w-100 opacity-50"
              className={`font-16 w-25 mobile-w-100 ${loader && "opacity-50"} `}
              disabled={loader}
              title={
                loader ? (
                  <div className="">
                    <div className="spinner-border spinner-border-sm text-white border-2"></div>
                    <span className="ms-2">Please wait...</span>
                  </div>
                ) : (
                  "Submit"
                )
              }
            />
          </form>
        </Col>
      </Row>
    </div>
  );
};

export default Contact;
