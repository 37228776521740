import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./header.css";
import MyButton from "../common/Button";
import { FaBars } from "react-icons/fa6";
const Header = () => {
  const [show, setShow] = useState(false);

  return (
    <header>
      <div className="header">
        <Container className="flex">
          <div>
            <a href="#home">
              <img
                className="logo-size"
                src="./assets/images/logo.png"
                alt="Logo"
              />
            </a>
          </div>
          <div className="flex mobile-none">
            <span>
              <a href="#home">Home</a>
            </span>
            <span>
              <a href="#benefits">Benefits</a>
            </span>
            <span>
              <a href="#how-it-work">How It Works</a>
            </span>
            <span>
              <a href="#contact">Early Access</a>
            </span>

            <span>
              <a href="#faqs">FAQs </a>
            </span>

            <span>
              <a href="#contact">Contact</a>
            </span>
          </div>
          <div className="flex mobile-none">
            {/* <MyButton
              onClick={() =>
                (window.location.href = "https://wkrbndev.workrobin.com/login")
              }
              className="header-btn f-bold"
              title={"Login"}
            /> */}
            <a href="#contact">
              <MyButton
                // onClick={() =>
                //   (window.location.href =
                //     "https://wkrbndev.workrobin.com/welcome")
                // }

                className="btn-common ms-4 f-12"
                title={"Get Started"}
              />
            </a>
          </div>
          <div className="desktop-none ">
            <FaBars onClick={() => setShow(true)} size={23} />
          </div>

          <Offcanvas
            placement="start"
            className="custom-offcanvas"
            show={show}
            onHide={() => setShow(false)}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                {" "}
                <img
                  className="logo-size"
                  src="./assets/images/logo.png"
                  alt="Logo"
                />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <p onClick={() => setShow(false)} className="f-16">
                <a href="#home">Home</a>
              </p>
              <p onClick={() => setShow(false)} className="f-16">
                <a href="#benefits">Benefits</a>
              </p>
              <p onClick={() => setShow(false)} className="f-16">
                <a href="#how-it-work">How It Works</a>
              </p>
              <p onClick={() => setShow(false)} className="f-16">
                <a href="#contact">Early Access</a>
              </p>
              <p onClick={() => setShow(false)} className="f-16">
                <a href="#faqs">FAQs</a>
              </p>
              <p onClick={() => setShow(false)} className="f-16">
                <a href="#contact">Contact</a>
              </p>
              {/* <p className="f-16">
                <a href="https://wkrbndev.workrobin.com/login">Login</a>
              </p> */}
            </Offcanvas.Body>
          </Offcanvas>
        </Container>
      </div>
    </header>
  );
};

export default Header;
