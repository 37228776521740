import React from "react";
import MyButton from "../common/Button";
import { Col, Row } from "react-bootstrap";

const HeroSection = () => {
  return (
    <Row className="w-100 gap-110">
      <Col md={7} className="center-column">
        <div className="">
          <div className="w-55 m-auto mobile-w-100">
            <h1 className="f-55 gilroy mobile-p-l-15">
              Manage Your
              <span className="second-color"> Workforce</span>, All in One Place
            </h1>
          </div>
          <div className="w-55 m-auto mobile-w-90">
            <p className="text-color font-inter gap-15 text-start f-16">
              Easily handle tasks, find quality contractors, and grow your
              business—all from one platform. <br />
              WorkRobin automates your contractor management—from onboarding to
              payments—so you can focus on what matters most: growing your
              business.
            </p>
            <div className="gap-30">
              <a href="#contact">
                <MyButton className="f-14" title={"Get Started"} />
              </a>
            </div>
          </div>
        </div>
      </Col>
      <Col md={5}>
        <img
          className="mobile-platform mobile-mt-15"
          src="/assets/images/RightImage1.png"
          alt=""
        />
      </Col>
    </Row>
  );
};

export default HeroSection;
